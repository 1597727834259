import React from "react";
import Container from "./layouts/container";
import PageGallery from "./pageGallery";
import PageBuilderContent from "./pageBuilderContent";
import Form from "./form";


function PageContent(page) {

  const {
    // _rawBody,
    pageBuilderThree,
    name,
    // slug,
    // title,
    form,
    maniGallery,
  } = page;

  // console.log(form);

  // const [count, setCount] = useState(0);
  // useEffect(()=> {
  //   setInterval(()=> {
  //     setCount(prevCount => prevCount + 1);
  //   }, 9000);
  // },[]);

return (
    <>
   <div>
    <article className="postCont">
      <Container>
          { pageBuilderThree && 
          <div className="mainPageWrapper">
            <PageBuilderContent content={pageBuilderThree} />
          </div>
          }
          { maniGallery && 
          <div>
            <PageGallery gallery={maniGallery} />
          </div>
          } 
          {form && <Form form={form}/>}
      </Container>
    </article>
    <h1 className="pageTitle">{name}</h1>
    </div>
    <div className="TitleWrapper">
      <hr className="line"/>
      </div>
     
    </>
  );
}

export default PageContent;