import React from "react";
import MailChimpForm from "./MailchimpForm";
import ContactForm from "./ContactForm";

const Form = ( {form} ) => {
const Form = (form)
let el = null;
if ( form.choose === "contact"){
el = <><ContactForm /></>
return el;
} else if ( form.choose === "mailchimp") {
  el = <><MailChimpForm /></>
  return el;
} else {
  el = null;
};

return ( 
    <>
    {Form}
    </>
    )
}
export default Form;