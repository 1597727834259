import React from "react"
import { graphql } from "gatsby"
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import PageContent from "../components/page-content";
import LayoutWork from "../components/layouts/layoutWork";


export const query = graphql`
query Page($id: String) {
  site: sanitySiteSettings {
    title
    keywords
    description
  }
  page: sanityPage(id: {eq: $id}) {
    _id
    _key
    id
    name
    slug {
      current
    }
    maniGallery {
      _key
      pressImages {
        _key
        _type
        alt
        link
        title
        asset {
          url
          title
          id
          altText
          _key
          _id
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      display
      _type
    }
    pageBuilderThree {
      ... on SanityPortableTextContainerThree {
        _key
        _type
        _rawBodyPortableText
        title
        bodyPortableText {
          _key
          _type
        }
      }
      ... on SanitySingleGallery {
        _key
        _type
        title
        images {
          alt
          _key
          asset {
            url
            title
            _id
            _key
            altText
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            id
          }
        }
        _rawBodyPortableText
        bodyPortableText {
          _key
          children {
            _key
          }
        }
        display
      }
      ... on SanityVideo {
        _key
        _type
        url
        title
        _rawBodyPortableText
        bodyPortableText {
          _key
          _rawChildren
          children {
            _key
          }
        }
      }
      ... on SanityPortableTextContainerFive {
        _key
        _type
        title
        display
        bodyPortableText {
          _type
          _key
        }
        _rawBodyPortableText
      }
      ... on SanityTwoColumn {
        _key
        _type
        title
        bodyPortableText {
          _key
          children {
            _key
          }
        }
        _rawBodyPortableText
        link {
          href
          where
          _key
        }
      }
    }
    form {
      choose
      _key
    }
  }
}
`
const PageTemp = (newProps) => {
  const { data, errors } = newProps;
  const page = data && data.page;

  console.log(data);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const site = (data || {}).site;

  console.log(data);

  return (
    <LayoutWork>
      <Seo
        title={page.name}
        description={site.description}
        keywords={site.keywords}
      />
      {page && <PageContent {...page} />}
    </LayoutWork>
  );
};

export default PageTemp