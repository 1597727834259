import React from "react";
import PortableText from "./portableText";
import Video from "./layouts/video";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const PageBuilderContent = ({content}) => {

  const pagesBuilder = (content) 
  .filter(y => !y.disabled)
  .filter(y => !y._key != null)
  .map((y) => { 
  let el = null;
  switch (y._type) {
    case "portableTextContainerThree":
      el = <div className="titleboxPages" key={y._key}>
        <div className="titleBox">
        <h3>{y.title}</h3></div>
        <div className="titlecopyBox">
        <PortableText blocks={y._rawBodyPortableText} />
        </div>
        </div>
      break;
      case "video":
      el = 
        <div className="marginVideo" key={y._key}>
                <h3 className="pageVideoTitle">{y.title}</h3>
                <div className="videoWrapper">
        <Video videoSrcURL={y.url} videoTitle={y.title}/> 
        </div>
                  { y._rawBodyPortableText && 
          <p className="pagesVideoDesc">
          <PortableText blocks={y._rawBodyPortableText} />
          </p>
          }
        </div>
      break;
      case "portableTextContainerFive":
        if (y.display === "center"){
          el = <div className="boxPages" key={y._key}>
          <div className="centerCopyBox">
          <PortableText blocks={y._rawBodyPortableText} />
          </div>
          </div>
        } else {
      el = <div className="boxPages" key={y._key}>
        <div className="copyBox">
        <PortableText blocks={y._rawBodyPortableText} />
        </div>
        </div>
        }
      break;
      case "singleGallery":
        if (y.display === "right") {
          el = <div key={y._key} className="galleryTextWrap">
          <div className="singleGalleryWrap" >
          <GatsbyImage image={getImage(y.images[0].asset.gatsbyImageData)} key={y.images[0]._key} alt={y.images[0].alt} />
          </div>
          <div className="copySideBox" key={y.bodyPortableText._key}>
          <PortableText blocks={y._rawBodyPortableText} />
          </div>
        </div>
        } else {
          el = <div key={y._key} className="galleryTextWrap">
                <div className="copySideBox" key={y.bodyPortableText._key}>
                <PortableText blocks={y._rawBodyPortableText} />
                </div>
                <div className="singleGalleryWrap">
                <GatsbyImage image={getImage(y.images[0].asset.gatsbyImageData)} key={y.images[0]._key} alt={y.images[0].alt} />
                </div>
              </div>
        }
      break;
      case "TwoColumn":
      el =
        <div className="ContactWrap" key={y._key}>
        <div className="contTitle">
        <h3>{y.title}</h3></div>
        <div className="contCopyBx">
        <PortableText blocks={y._rawBodyPortableText} />
        <a className="pcEachC" href={`${y.link.href}`} target="_blank" rel="noindex nofollow">{y.link.where}</a>
        </div>
        </div>
      break;
      default:
      el = null;
  }
  return el;
});


  return (
<>
{pagesBuilder}

</>
    )
}
 
export default PageBuilderContent;