import React from "react";

const ContactForm = () => {

    return (
        <>
        <div className="cWrap">
        <div className="csWrap">
        <form method="post" 
        netlify-honeypot="bot-field" 
        data-netlify="true" 
        name="contact"
        action="/thank-you"
        >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
            <div className="nameLast">
                <input className="half" name="Name" required="required" placeholder="Name *" type="text" />
                <input className="half" name="LastName" required="required" placeholder="Last Name *" type="text" />
            </div>
            <div className="intern">
                <input className="place cInput bg-offwhite tracking-wide" name="Email" required="required" placeholder="Email *" type="text" />
            </div>
            <div className="intern">
                <textarea className="place cInput bg-offwhite tracking-wide"  name="Message" placeholder="Message *" required="required" />
            </div>
            <label for="inquiry">
            <div className="intern cText">
            <p className="tracking-wide">Select which applies to your inquiry:</p>
            </div>
            <div className="selectWrap">
            <div className="select cText">
            <span>
                    <label className="optionClass" name="inquiry" value="general">
                    <input className="radioBB" required="required" type="radio" name="inquiry" id="general" value="General" />
                    <p className="pl-4">General</p>
                    </label>
                </span>
                <span>
                    <label className="optionClass" name="inquiry" value="press">
                    <input className="radioBB" required="required" type="radio" name="inquiry" id="press" value="Press" />
                    <p className="pl-4">Press</p>
                    </label>
                </span>
                <span>
                    <label className="optionClass" name="inquiry" value="internship">
                    <input className="radioBB" required="required" type="radio" name="inquiry" id="internship" value="Internship" />
                    <p className="pl-4">Internship</p>
                    </label>
                </span>
            </div>
            <button type="submit" className="cFormCTA">Send</button>
            </div>
            </label>
        </form>
        </div>
        </div>
        </>


    );
}


export default ContactForm;