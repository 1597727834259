import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";

const PageGallery = ({gallery}) => {

  const [isCarrGrid, setIsCarrGrid] = useState(false);
  const toggleCarrGrid = () => {
    setIsCarrGrid(!isCarrGrid);
    };
  
  const [isGrid, setIsGrid] = useState(false);
  const toggleGrid = () => {
  setIsGrid(!isGrid);
  };
  
  const [isIndex, setIsIndex] = useState(0);

  const Zoom = ( index ) => {
    setIsIndex(index);
    setIsCarrGrid(!isCarrGrid);
  };
  
  let maxIndex = gallery.pressImages.length;
  
  let newMaxIndex = maxIndex - 1;
  const Add = (isIndex, newMaxIndex) => {
    if(isIndex === newMaxIndex) {
      setIsIndex( isIndex = 0 );
    } else if (isIndex === maxIndex){
      setIsIndex( isIndex = newMaxIndex );
    } 
    else {
      setIsIndex( isIndex + 1 );
    };
  };

  const Less = (isIndex, newMaxIndex) => {
    let maxIndex = gallery.pressImages.length;
    if(isIndex === 0) {
      setIsIndex( isIndex = maxIndex - 1 );
    } else if(isIndex === newMaxIndex){
      setIsIndex( newMaxIndex - 1 );
    }
    else {
      setIsIndex( isIndex - 1 );
    };
  };
  

const inlineGallery = ({gallery})
let gal = null;
if ( gallery !== null) {
if ( gallery.display === "Carrousel") {
  gal = 
  <>
    <div id="Top" className={isCarrGrid ? '' : 'carr-post-container'}>
    <div className={isCarrGrid ? 'gallGridWrap' : 'carr-post-wrapper'}>
      { !isCarrGrid && 
      <div className={isCarrGrid ? 'gallGrid': 'carr-post-content'}>
        <GatsbyImage loading="eager" objectFit="contain" className={isCarrGrid ? '' : 'carr-post-element'} key={gallery.pressImages[isIndex]._key} image={gallery.pressImages[isIndex].asset.gatsbyImageData} alt={`${gallery.pressImages[isIndex].alt}`} />
        </div>
      }
{ isCarrGrid && 
      <div className={isCarrGrid ? 'gallGrid': 'carr-post-content'}>
      {gallery.pressImages.map((i, index)=><div role="button" onClick={()=>{Zoom(index)}}>
<a href="#Top"><GatsbyImage objectFit={isCarrGrid ? 'cover' : 'cover'} index={index} key={i._key} image={i.asset.gatsbyImageData} alt={`${i.alt} image # ${index + 1}`} /></a></div>)}
      </div>
      }
    </div>
    </div>
    { !isCarrGrid && 
    <>
        <div className="PagegallControls"> 
        <a href="#backTop" className="viewCTA" onClick={toggleCarrGrid}>{isCarrGrid ? 'View Gallery' : 'View All'}</a>
        <div className="nextControls">
        <button type="button" className="viewCTA" onClick={()=>{Less(isIndex,newMaxIndex)}}>Prev </button><span className="g1oneOf">{isIndex + 1} of {gallery.pressImages.length} </span><button type="button" className="viewCTA" onClick={()=>{Add(isIndex,newMaxIndex)}}>Next </button>
        </div></div>
        </>
    }
    { isCarrGrid && 
    <div className="PagegallControls"> 
      All {gallery.pressImages.length} <a href="#Top" className="viewCTA" onClick={toggleCarrGrid}>{isCarrGrid ? 'View Gallery' : 'View All'}</a></div>
}
</>
return gal;
} 
if ( gallery.display === "Vertical") {
  gal = 
  <>
  <div id="backTop" className={isGrid ? '' : 'layout-container'}>
  <div className={isGrid ? 'gallGridWrap' : 'layout-wrapper'}>
  <div className={isGrid ? 'gallGrid': 'layout-content'}>
  {gallery.pressImages.map((i)=><GatsbyImage loading="eager" objectFit={isGrid ? 'cover' : 'contain'} className={isGrid ? '' : 'layout-element'} key={i._key} image={i.asset.gatsbyImageData} alt={i.alt} />)}
  </div>
  </div>
  </div>
  <div className="PagegallControls">  
  <div> All {gallery.pressImages.length}</div><a href="#backTop" type="button" className="viewCTA" onClick={toggleGrid}>{isGrid ? 'View Gallery' : 'View All'}</a></div>
</>
return gal;
} 
}
  else {
    gal = null;
    return gal;
  };
  return (
<>
<div>
  {inlineGallery}
</div>
</>
    )
}
 
export default PageGallery;